html[data-theme=light] {
	--bb-color-Primary: #FBFBFB;
	--bb-color-Secondary: #8D9194;
	--bb-color-NegativeTextColor: #0C0E0F;
	--bb-color-TransparentWhiteColor: rgb(255, 255, 255, 0.8);
	--bb-color-TopLayerObjectBg: #1B2429;
	--bb-color-NavBarBgColor: #131B20;
	--bb-color-WhiteTextColor: #FFFFFF;
	--bb-color-PageBg: #131B20;
	--bb-color-InputBg: #131B20;
	--bb-color-Border: #555F66;
	--bb-color-StrokeColor: #2E3940;
	--bb-color-LightGrayBg: #1B2329;
	--bb-color-ViewedBg: #182126;
	--bb-color-ButtonBg: #212B30;
	--bb-color-ButtonBgHover: #2B3840;
	--bb-color-Gray: #495054;
	--bb-color-TooltipBg: #1B272E;
	--bb-color-TextColor: #FFF;
	--bb-color-Placeholder: #939799;
	--bb-color-Black: #101012;
	--bb-color-Accent1: #B6F057;
	--bb-color-Accent1Light: #DCFFA4;
	--bb-color-Accent1Dark: #4A7306;
	--bb-color-Accent1Hover: #A5DE47;
	--bb-color-Accent1Text: #BDE976;
	--bb-color-Accent2: #F3BEF7;
	--bb-color-Accent2Light: #F5D7F7;
	--bb-color-Accent2Dark: #6A0673;
	--bb-color-Accent2Hover: #E1A8E5;
	--bb-color-Accent2Text: #ED8FF4;
	--bb-color-Highlight1: ;
	--bb-color-Highlight1Bg: ;
	--bb-color-Highlight2: ;
	--bb-color-Highlight2Bg: ;
	--bb-color-Highlight3: ;
	--bb-color-Highlight3Bg: ;
	--bb-color-Highlight4: ;
	--bb-color-Highlight4Bg: ;
	--bb-color-Highlight5: ;
	--bb-color-Success: #6CDC88;
	--bb-color-Danger: #FF949B;
	--bb-color-DangerHover: #DA5C64;
	--bb-color-DangerBg: #332C2D;
	--bb-color-MessageBg: linear-gradient(90deg, #228080 0.08%, #2A6896 102.72%);
}
